import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faUtensils,
  faPlus,
  faBars,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import mainService from "../../services/mainService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Multiselect } from "react-widgets";
import Base from "../Base";
import Item from "../../components/Item";
import { selectPlates } from "../../utils/selectors";
import { isLoggedIn } from "../../utils/selectors";
import { fetchPlates, removeItem } from "../../features/plates";
import foodService from "../../services/foodService";
import { HStack, Checkbox } from "@chakra-ui/react";
import swal from "sweetalert";
import { Spinner } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { isMobile } from 'react-device-detect';

library.add(fab, faUtensils, faPlus, faBars, faEllipsisV);

function Plates() {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { loading, error, data, totalPages } = useSelector(selectPlates);

  const isConnected = useSelector(isLoggedIn);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  // const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [subType, setSubType] = useState(null);
  const [price, setPrice] = useState("");
  const [priceAchat, setPriceAchat] = useState("");
  const [description, setDescription] = useState("");
  const [nutritional, setNutritional] = useState("");
  const [timer, setTimer] = useState("");
  const [ingredient, setIngredient] = useState([]);
  const [obstacle, setObstacle] = useState([]);
  const [complement, setComplement] = useState([]);
  const [gamelle, setGamelle] = useState([]);
  const [day, setDay] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [types, setTypes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [obstacles, setObstacles] = useState([]);
  const [complements, setComplements] = useState([]);
  const [gamelles, setGamelles] = useState([]);
  const [vDay, setVday] = useState(false);
  const [vDate, setVdate] = useState("");
  const [subTypes, setSubTypes] = useState([]);
  const [resaurantsList, setResaurantsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  // const [totalPages, setTotalPages] = useState(0); // Nombre total de pages
  const ITEMS_PER_PAGE = 10; // Nombre d'éléments par page
  const handlePrevious = async () => {
    if (currentPage > 1){
      setCurrentPage(currentPage - 1);
      dispatch(fetchPlates(currentUser.restaurant, statusFilters, (currentPage + 1), limit, search));
    } 
    
  };

  const handleNext = async () => {
    if (currentPage < totalPages){
      setCurrentPage(currentPage + 1);
      dispatch(fetchPlates(currentUser.restaurant, statusFilters, (currentPage + 1), limit, search));
    } 

  };

  const [resaurants, setResaurants] = useState([]);

  const [vName, setVname] = useState(false);
  const [vPrice, setVprice] = useState(false);
  const [vPriceAchat, setVPriceAchat] = useState(false);
  const [vType, setVtype] = useState(false);
  // const [vCategory, setVcategory] = useState(false);

  const [dType, setDtype] = useState(false);
  // const [dCategory, setDcategory] = useState(false);
  const [dDesc, setDdesc] = useState(false);
  const [image, setImage] = useState(false);

  const [loader, setLoader] = useState(false);
  const [statusFilters, setStatusFilters] = useState("today");

  //const [ filter, setFilter ] = useState('')
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [eDate, setEndDate] = useState("");
  const [repeat, setRepeat] = useState("");
  const today = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  const [sDate, setStartDate] = useState(today());

  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [selectSousResto, setSelectSousResto] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [selectSousRestoDetail, setSelectSousRestoDetail] = useState({});
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const token = currentUser.token;
  const { getRootProps, getInputProps } = useDropzone({
    // multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setImage("");
    },
  });
  function validate() {
    setVday(false);
    setVdate(false);
  }

  const thumbs = files.map((file) => (
    <div className="thumbs" key={file.name}>
      <div className="thumbs-inner">
        <img
          src={file.preview}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const handleChangeSearch = (event) => {
    setSearch(event.target.value)

    // Effacer le timer précédent
    if (debounceTimeout) {
        clearTimeout(debounceTimeout);
    }
    // Définir un nouveau timer
    setDebounceTimeout(setTimeout(() => {
        console.log("Saisie terminée :", search);
        dispatch(fetchPlates(currentUser.restaurant, statusFilters, currentPage, limit, search));
    }, 500));
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    dispatch(fetchPlates(currentUser.restaurant, statusFilters, currentPage, limit));
  }, [dispatch]);

  useEffect(() => {
    foodService
      .getFoodType()
      .then((response) => {
        setTypes(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    foodService
      .getIngredients()
      .then((response) => {
        setIngredients(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    foodService
      .getFoodSubType()
      .then((response) => {
        setSubTypes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    mainService
      .getSouseRestaurant(token)
      .then((response) => {
        setResaurantsList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setObstacles([
      { value: "Vide", label: intl.formatMessage({ id: "empty" }) },
      { value: "Poulet", label: intl.formatMessage({ id: "chicken" }) },
      { value: "Boeuf", label: intl.formatMessage({ id: "beef" }) },
      {
        value: "Poisson Fumé",
        label: intl.formatMessage({ id: "smokedFish" }),
      },
      { value: "Peaux", label: intl.formatMessage({ id: "kandas" }) },
      { value: "Porc", label: intl.formatMessage({ id: "pork" }) },
      {
        value: "Boeuf et Peaux",
        label: intl.formatMessage({ id: "beefAndKandas" }),
      },
      {
        value: "Boeuf et Poisson fumé",
        label: intl.formatMessage({ id: "beefAndSmokedFish" }),
      },
      {
        value: "Boeuf et Peaux et Poisson fumé",
        label: intl.formatMessage({ id: "beefAndKandasAndSmokedFish" }),
      },
      {
        value: "Boeuf et Poisson fumé",
        label: intl.formatMessage({ id: "beefAndSmokedFish" }),
      },
      {
        value: "Poisson autre que sole",
        label: intl.formatMessage({ id: "fishOtherThanSole" }),
      },
      { value: "Crevettes", label: intl.formatMessage({ id: "shrimp" }) },
      { value: "Gambas", label: intl.formatMessage({ id: "gambas" }) },
      {
        value: "Viande de brousse",
        label: intl.formatMessage({ id: "bushMeat" }),
      },
      { value: "Escargots", label: intl.formatMessage({ id: "snails" }) },
      { value: "Sole", label: "Sole" },
    ]);
    setComplements([
      { value: "Vide", label: intl.formatMessage({ id: "empty" }) },
      {
        value: "Riz Parfumé",
        label: intl.formatMessage({ id: "fragrantRice" }),
      },
      { value: "Riz Nature", label: intl.formatMessage({ id: "plainRice" }) },
      {
        value: "Frites de Plantain",
        label: intl.formatMessage({ id: "plantainFries" }),
      },
      {
        value: "Frites de Pommes",
        label: intl.formatMessage({ id: "frenchFries" }),
      },
      { value: "Miondos", label: "Miondos" },
      {
        value: "Baton de Manioc",
        label: intl.formatMessage({ id: "cassavaStick" }),
      },
      {
        value: "Tubercules de Manioc",
        label: intl.formatMessage({ id: "cassava" }),
      },
      { value: "Fufu", label: "Fufu" },
      { value: "Gari", label: "Gari" },
      {
        value: "Plantain Vapeur",
        label: intl.formatMessage({ id: "steamedPlantain" }),
      },
      { value: "Pommes", label: intl.formatMessage({ id: "potatoes" }) },
      { value: "Macabo", label: "Macabo" },
      { value: "Râpé", label: intl.formatMessage({ id: "gratedMacabo" }) },
      { value: "Igname", label: intl.formatMessage({ id: "yam" }) },
      { value: "Spaghetti", label: "Spaghetti" },
      { value: "Banane", label: intl.formatMessage({ id: "banana" }) },
      {
        value: "Couscous Mais",
        label: intl.formatMessage({ id: "couscousCorn" }),
      },
      { value: "Riz Sauté", label: intl.formatMessage({ id: "friedRice" }) },
      {
        value: "Riz Sénégalais",
        label: intl.formatMessage({ id: "senegaleseRice" }),
      },
      {
        value: "Plantains Tapés",
        label: intl.formatMessage({ id: "tapedPlantains" }),
      },
      {
        value: "Couscous Manioc",
        label: intl.formatMessage({ id: "couscousCassava" }),
      },
    ]);

    setGamelles([
      "Vide",
      "Resto",
      "Wazi - Alu",
      "Grand Contenant",
      "Wazi - Plastique",
      "Autres",
    ]);
  }, []);

  // Nettoyer le timer lors du démontage du composant
  useEffect(() => {
    return () => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    };
  }, [debounceTimeout])


  if (!isConnected) {
    return <Navigate replace to="/login" />;
  }

  const toggle = () => {
    resetForm();
    setIngredients(ingredients);
    setObstacles(obstacles);
    setComplements(complements);
    setGamelles(gamelles);
    setResaurantsList(resaurantsList);
    setModal(!modal);
  };

  const toggleConfirm = () => {
    const title =
      id === 0
        ? intl.formatMessage({ id: "confirmAdditionFood" })
        : intl.formatMessage({ id: "confirmFoodUpdate" });
    const buttonText =
      id === 0
        ? intl.formatMessage({ id: "add" })
        : intl.formatMessage({ id: "update" });
    const successText =
      id === 0
        ? intl.formatMessage({ id: "foodAddedSuccessfully" })
        : intl.formatMessage({ id: "foodUpdatedSuccessfully" });
    console.log("hunter debug : ", id);
    swal({
      text: title,
      title: "Confirmation",
      icon: "info",
      buttons: ["Annuler", buttonText],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        setLoader(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("gamelle", gamelle);
        formData.append("description", description);
        formData.append("foodType", type);
        formData.append("foodCategory", 1);
        formData.append("nutritional_value", nutritional);
        formData.append("price", Math.ceil(price));
        formData.append("purchase_price", Math.ceil(priceAchat));
        // formData.append("published_day", day);
        formData.append("repeat", repeat);
        formData.append("foodSubType", subType);
        let sd = sDate;
        if (sDate == "") {
          sd = today();
        }
        formData.append("publication_start_date", sd);
        formData.append("publication_end_date", eDate);
        // const times = new Date(timer * 1000).toISOString().slice(11, 19);
        formData.append("cooking_time", timer);
        const forpublished_days = [];
        for (let i = 0; i < day.length; i++) {
          forpublished_days.push(day[i]);
        }
        formData.append("published_day", forpublished_days);
        const foringredients = [];
        for (let i = 0; i < ingredient.length; i++) {
          foringredients.push(ingredient[i].name);
        }
        formData.append("ingredient", foringredients);

        const substitute_food = [];
        for (let i = 0; i < suggestion.length; i++) {
          substitute_food.push(String(suggestion[i].id));
        }
        if (substitute_food.length > 0) {
          formData.append("substitute_foods", substitute_food);
        }

        const fiComplement = [];
        for (let i = 0; i < complement.length; i++) {
          fiComplement.push(complement[i].value);
        }
        const fiObstacle = [];
        for (let i = 0; i < obstacle.length; i++) {
          fiObstacle.push(obstacle[i].value);
        }

        const fiRestaurant = [];
        for (let i = 0; i < resaurants.length; i++) {
          fiRestaurant.push(resaurants[i].id);
        }

        formData.append("obstacles", fiObstacle);
        formData.append("complements", fiComplement);
        formData.append("sous_restaurant", fiRestaurant);
        const fiSelectSousRestoDetail = {};
        if (fiRestaurant.length > 0) {
          for (let i = 0; i < resaurants.length; i++) {
            const element = resaurants[i].id.toString();
            if (selectSousRestoDetail[element].price <= 0) {
              setVprice(true);
              setLoader(false);
              return;
            }

            if (selectSousRestoDetail[element].priceAchat <= 0) {
              setLoader(false);
              setVPriceAchat(true);
              return;
            }

            const fiComplement = [];
            for (
              let i = 0;
              i < selectSousRestoDetail[element].complement.length;
              i++
            ) {
              fiComplement.push(
                selectSousRestoDetail[element].complement[i].value
              );
            }
            const fiObstacle = [];
            for (
              let i = 0;
              i < selectSousRestoDetail[element].obstacle.length;
              i++
            ) {
              fiObstacle.push(selectSousRestoDetail[element].obstacle[i].value);
            }
            const foringredients = [];
            for (
              let i = 0;
              i < selectSousRestoDetail[element].ingredient.length;
              i++
            ) {
              foringredients.push(
                selectSousRestoDetail[element].ingredient[i].name
              );
            }

            selectSousRestoDetail[element].complement = fiComplement;
            selectSousRestoDetail[element].obstacle = fiObstacle;
            selectSousRestoDetail[element].ingredient = foringredients;
            fiSelectSousRestoDetail[element] = selectSousRestoDetail[element];
          }

          formData.append(
            "sous_restaurant_detail",
            JSON.stringify(fiSelectSousRestoDetail)
          );
        }

        if (id === 0) {
          formData.append("foodPicture", files[0]);
          foodService
            .create(formData)
            .then((response) => {
              setLoader(false);
              resetForm();
              toggle();
              dispatch(fetchPlates(currentUser.restaurant));
              swal(successText, {
                icon: "success",
              });
            })
            .catch((err) => {
              setLoader(false);
              // resetForm();
              if (err.response.data.message.includes("foodPicture")) {
                swal("Erreur", "L'image du plat est invalide", "error");
              } else if (Math.floor(err.response.status / 400) === 1) {
                swal("Erreur", err.response.data.message, "error");
              } else if (err) {
                swal("Erreur", err.message, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            });
        } else {
          if (files.length > 0) {
            formData.append("foodPicture", files[0]);
          } else {
            // formData.append("foodPicture", "");
          }
          foodService
            .update(id, formData)
            .then((response) => {
              dispatch(fetchPlates(currentUser.restaurant));
              setLoader(false);
              resetForm();
              toggle();
              swal(successText, {
                icon: "success",
              });
            })
            .catch((err) => {
              setLoader(false);
              if (err.response.data.message.includes("foodPicture")) {
                swal("Erreur", "L'image du plat est invalide", "error");
              } else if (
                Math.floor(err.response.status / 400) === 1 ||
                Math.floor(err.response.status / 500) === 1
              ) {
                swal("Erreur", err.response.data.message, "error");
              } else if (err.response.data.message.includes("foodType")) {
                swal("Erreur", "Check food Type", "error");
              } else if (err) {
                swal("Erreur", err.message, "error");
              } else {
                swal.stopLoading();
                swal.close();
              }
            });
        }
      }
    });
  };

  function toSecond(time) {
    const [hours, minutes, seconds] = time.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  const toggleConfirmDelete = (p) => {
    swal({
      text: "Confirmer la suppression du plat",
      title: "Suppression",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        foodService
          .remove(p.id)
          .then(() => {
            dispatch(removeItem(p));
            setMessage("Food supprimé avec succès.");
            setItemToDelete(p);
            swal("Food supprimé avec succès", {
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            });
          });
      }
    });
  };

  const toggleShow = () => setShow(!show);

  const resetForm = () => {
    setId(0);
    setName("");
    setPrice("");
    setDescription("");
    setNutritional("");
    setType("");
    setGamelle("");
    setSubType("");
    setTimer("");
    setIngredient([]);
    setSuggestion([]);
    setObstacle([]);
    setComplement([]);
    setFiles([]);
    setDay([]);
    setResaurants([]);
    setImage(false);
  };

  const handleFilter = (event) => {
    setStatusFilters(event.value);
    dispatch(fetchPlates(currentUser.restaurant, event.value));
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "gamelle":
        setGamelle(value);
        break;
      case "price":
        if (value == "") {
          setPrice("");
        } else setPrice(Math.ceil(value));
        break;
      case "price_achat":
        if (value == "") {
          setPriceAchat("");
        } else setPriceAchat(Math.ceil(value));
        break;
      case "end":
        setEndDate(value);
        break;
      case "start":
        setStartDate(value);
        break;
      case "repeat":
        if (value === "") {
          setRepeat("");
        } else {
          setRepeat(value === "true");
        }
        break;
      // case "category":
      //   setCategory(value);
      //   break;
      case "type":
        setType(value);
        break;
      case "subtype":
        setSubType(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "nutritional":
        setNutritional(value);
        break;
      case "ingredients":
        setIngredients(value);
        break;
      case "obstacles":
        setObstacles(value);
        break;
      case "sousrestaurant":
        setResaurants(value);
        break;
      case "complements":
        setComplements(value);
        break;
      case "suggestions":
        setSuggestion(value);
        break;
      case "time":
        setTimer(value);
        break;
      default:
        break;
    }
  };

  const handleChangeSousResto = (event, id) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const newObj = { ...selectSousRestoDetail };

    switch (name) {
      case "gamelle":
        newObj[id].gamelle = value;
        // setGamelle(value);
        break;
      case "price":
        if (value == "") {
          newObj[id].price = "";
          // setPrice("");
        } else newObj[id].price = Math.ceil(value);
        // setPrice(Math.ceil(value));
        break;
      case "price_achat":
        if (value == "") {
          newObj[id].priceAchat = "";
          // setPriceAchat("");
        } else newObj[id].priceAchat = Math.ceil(value);
        // setPriceAchat(Math.ceil(value));
        break;
      case "end":
        newObj[id].eDate = value;
        // setEndDate(value);
        break;
      case "start":
        newObj[id].sDate = value;
        // setStartDate(value);
        break;
      case "repeat":
        if (value === "") {
          newObj[id].repeat = "";
          // setRepeat("");
        } else {
          newObj[id].repeat = value;
          // setRepeat(value === "true");
        }
        break;
      case "nutritional":
        newObj[id].nutritional = value;
        // setNutritional(value);
        break;
      case "ingredients":
        newObj[id].ingredient = value;
        // setIngredients(value);
        break;
      case "obstacles":
        newObj[id].obstacle = value;
        // setObstacles(value);
        break;
      case "complements":
        newObj[id].complement = value;
        // setComplements(value);
        break;
      case "suggestions":
        newObj[id].suggestion = value;
        // setSuggestion(value);
        break;
      case "time":
        newObj[id].timer = value;
        // setTimer(value);
        break;
      default:
        break;
    }

    setSelectSousRestoDetail(newObj);
  };

  const handleChangeSousRestoMul = (item, type, id) => {
    const newObj = { ...selectSousRestoDetail };

    if (type == "ingredients") {
      newObj[id].ingredient = item;
    } else if (type == "obstacles") {
      newObj[id].obstacle = item;
    } else if (type == "complements") {
      newObj[id].complement = item;
    } else if (type == "Suggestion") {
      newObj[id].suggestion = item;
    } else if (type == "publications") {
      newObj[id].day = item;
    }
    setSelectSousRestoDetail(newObj);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validate();

    if (name === "") {
      setVname(true);
    }
    if (price <= 0) {
      setVprice(true);
    }

    if (priceAchat <= 0) {
      setVPriceAchat(true);
    }
    // if (category === null) {
    //   setVcategory(true);
    // }
    if (type === null) {
      setVtype(true);
    }
    if (name !== "" && price > 0 && type !== null) {
      setVname(false);
      setVprice(false);
      setVPriceAchat(false);
      setVtype(false);
      // setVcategory(false);
      toggleConfirm();
    }
  };

  const edit = async (plate) => {
    setId(plate.id);
    setName(plate.name);
    setGamelle(plate.gamelle ? plate.gamelle : "");
    setPrice(plate.price);
    setPriceAchat(plate.purchase_price);
    setDescription(plate.description);
    const typeID = types.find(
      (obj) => obj.name === plate.foodType || obj.id === plate.foodType
    ).id;
    const subTypeID = subTypes.find(
      (obj) => obj.name === plate.foodSubType || obj.id === plate.foodSubType
    ).id;
    setType(/*types.indexOf(*/ typeID /*)*/);
    setSubType(/*types.indexOf(*/ subTypeID /*)*/);
    if (plate.nutritional_value == "null" || plate.nutritional_value == null) {
      setNutritional("");
    } else {
      setNutritional(plate.nutritional_value ?? "");
    }
    setTimer(plate.cooking_time);
    const ingredients = [];
    if (plate.ingredients !== null) {
      for (var i = 0; i < plate.ingredients.length; i++) {
        ingredients.push(plate.ingredients[i]);
      }
    }
    setIngredient(ingredients);

    const initSetday = [];
    if (plate.published_day) {
      for (var y = 0; y < plate.published_day.length; y++) {
        initSetday.push(plate.published_day[y].name);
      }
    }
    setDay(initSetday);

    const initobstacles = [];
    if (
      plate.obstacles &&
      plate.obstacles[0] &&
      plate.obstacles[0].length > 1
    ) {
      for (var i = 0; i < plate.obstacles.length; i++) {
        let val = obstacles.find((obj) => obj.value === plate.obstacles[i]);
        initobstacles.push(val);
      }
    }

    setObstacle(initobstacles);

    const initcomplements = [];
    if (
      plate.complements &&
      plate.complements[0] &&
      plate.complements[0].length > 1
    ) {
      for (var i = 0; i < plate.complements.length; i++) {
        let val = complements.find((obj) => obj.value === plate.complements[i]);
        initcomplements.push(val);
      }
    }
    setComplement(initcomplements);

    const initrestaurant = [];
    if (
      plate.sous_restaurant &&
      plate.sous_restaurant[0] &&
      plate.sous_restaurant[0].length > 0
    ) {
      console.log("hunter debug", plate.sous_restaurant);
      if(plate.sous_restaurant.length > 1){
        let val = resaurantsList.find(
          (obj) => obj.id === Number(plate.sous_restaurant[0])
        );
        initrestaurant.push(val);
      }else{
        for (var i = 0; i < plate.sous_restaurant[0].split(",").length; i++) {
          let val = resaurantsList.find(
            (obj) => obj.id === Number(plate.sous_restaurant[0].split(",")[i])
          );
          initrestaurant.push(val);
        }
      }
    }
    setResaurants(initrestaurant);

    const suggestions = [];
    if (plate.substitute_food !== null) {
      for (var i = 0; i < plate.substitute_food.length; i++) {
        suggestions.push(plate.substitute_food[i]);
      }
    }
    setSuggestion(suggestions);
    setFiles([]);

    setImage(plate.foodPicture);
    setModal(true);
    if (
      plate.sous_restaurant &&
      plate.sous_restaurant[0] &&
      plate.sous_restaurant[0].length > 0
    ) {
      setLoader(true);
      const formData = new FormData();
      formData.append("resto_ids", plate.sous_restaurant[0].split(","));
      foodService
        .sousreso(plate.id, formData)
        .then((response) => {
          setLoader(false);
          let listssd = {};

          for (let i = 0; i < response.data.response.length; i++) {
            const ingredients = [];
            const item = response.data.response[i];
            if (item.ingredients !== null) {
              for (var j = 0; j < item.ingredient.length; j++) {
                ingredients.push(item.ingredient[j]);
              }
            }

            const initobstacles = [];
            if (
              item.obstacle &&
              item.obstacle[0] &&
              item.obstacle[0].length > 1
            ) {
              for (var l = 0; l < item.obstacle.length; l++) {
                let val = obstacles.find(
                  (obj) => obj.value === item.obstacle[l]
                );
                initobstacles.push(val);
              }
            }

            const initcomplements = [];
            if (
              item.complement &&
              item.complement[0] &&
              item.complement[0].length > 1
            ) {
              for (var t = 0; t < item.complement.length; t++) {
                let val = complements.find(
                  (obj) => obj.value === item.complement[t]
                );
                initcomplements.push(val);
              }
            }

            const initday = [];
            if (item.day) {
              for (var y = 0; y < item.day.length; y++) {
                initday.push(item.day[y].name);
              }
            }
            listssd[item.sous_resto_id.toString()] = {
              price: item.price,
              priceAchat: item.price_achat,
              nutritional: item.nutritional,
              timer: item.cooking_time,
              ingredient: ingredients,
              obstacle: initobstacles,
              complement: initcomplements,
              gamelle: item.gamelle,
              suggestion: [],
              day: initday,
            };
          }
          if(currentUser.role != "underrestaurant"){
            setSelectSousRestoDetail(listssd);
            console.log(selectSousRestoDetail);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  };

  const handleDelete = (plate) => {
    toggleConfirmDelete(plate);
  };

  const handleDeactivate = (plate) => {
    toggleConfirmDeactivate(plate);
  };
  const toggleConfirmDeactivate = (p) => {
    swal({
      text: intl.formatMessage({ id: "confirmFoodDeactivation" }),
      title: "Désactivation",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        foodService
          .deactivate(p.id)
          .then(() => {
            // dispatch(removeItem(p));
            setMessage("Food Désactiver avec succès.");
            // setItemToDelete(p);
            swal("Food Désactiver avec succès", {
              icon: "success",
            });
            dispatch(fetchPlates(currentUser.restaurant, event.value,currentPage));
          })
          .catch((err) => {
            console.log(err);
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            });
          });
      }
    });
  };

  const handleActivate = (plate) => {
    toggleConfirmActivate(plate);
  };
  const toggleConfirmActivate = (p) => {
    swal({
      text: intl.formatMessage({ id: "confirmActivationFood" }),
      title: "Activation",
      icon: "warning",
      buttons: ["Non", "Oui"],
    }).then((value) => {
      if (value) {
        foodService
          .activate(p.id)
          .then(() => {
            dispatch(removeItem(p));
            setMessage("Food Activer avec succès.");
            // setItemToDelete(p);
            swal("Food Activer avec succès", {
              icon: "success",
            });
            dispatch(
              fetchPlates(currentUser.restaurant, event.value, currentPage, limit)
            );
          })
          .catch((err) => {
            console.log(err);
            swal("Erreur lors du traitement de la requete", {
              icon: "error",
            });
          });
      }
    });
  };

  const setDetailSousPlat = (val) => {
    selectSousResto;
    setSelectSousResto(val.toString() == selectSousResto ? "" : val.toString());
  };

  const intSousResto = (val) => {
    for (let i = 0; i < val.length; i++) {
      const element = val[i];
      if (!selectSousRestoDetail[element.id.toString()]) {
        selectSousRestoDetail[element.id.toString()] = {
          price: "",
          priceAchat: "",
          nutritional: "",
          timer: "",
          ingredient: [],
          obstacle: [],
          complement: [],
          gamelle: "",
          suggestion: [],
          day: [],
          sDate: "",
          eDate: "",
          repeat: "",
        };
      }
    }
    setResaurants(val);
  };

  const filters = [
    { value: "today", label: intl.formatMessage({ id: "today" }) },
    { value: true, label: intl.formatMessage({ id: "enabled" }) },
    { value: false, label: intl.formatMessage({ id: "disabled" }) },
    { value: null, label: intl.formatMessage({ id: "all" }) },
  ];

  const closeBtn = (
    <FontAwesomeIcon
      className="close"
      onClick={toggle}
      style={{ cursor: "pointer", fontSize: "1.5rem" }}
      icon={faTimes}
    />
  );

  async function handleCreate(name) {
    let newOption = { name, id: ingredients.length + 1 };
    setIngredient([...ingredient, newOption]);
    setIngredients((data) => [newOption, ...data]);

    try {
      await foodService.setIngredients({ name });
    } catch (e) {
      console.log("ERROR CREATING INGREDIENT");
    }
  }
  async function handleCreateComplements(name) {
    let newOption = { value:name, label: name };
    setComplements([...complements, newOption]);
    setComplement([...complement, name])

  }
  async function handleCreateObstacles(name){
    let newOption = { value:name, label: name };
    setObstacles([...obstacles, newOption]);
    setObstacle([...obstacle, name]);
  }

  return (
    <Base>
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
            <div className="d-flex justify-content-center align-items-center w-2-4">
              <Select
                onChange={handleFilter}
                styles={{width:"300px"}}
                defaultValue={{
                  label: intl.formatMessage({ id: "today" }),
                  value: 'today',
                }}
                type="select"
                name="filter"
                id="filter"
                options={filters}
              ></Select>
              <Input
                type="text"
                className="search-input ms-4"
                value={search}
                name="search"
                onChange={handleChangeSearch}
                placeholder={intl.formatMessage({ id: "search" })}
              />
            </div>
            <h1 className="fs-3 pt-2" style={ isMobile ? {fontSize: "1.2em", marginLeft: "10px"} : {}}>
              {intl.formatMessage({ id: "foodManagement" })}
            </h1>
            {currentUser.role != "underrestaurant" ? (
              <Button
                className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                onClick={toggle}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            ) : ("")
            }
          </div>
        </div>

        <Row>
          <SweetAlert
            title={message}
            show={show}
            type="success"
            onConfirm={toggleShow}
          />
          <Col md="12">
            <Modal
              className="wazi-modal"
              isOpen={modal}
              toggle={toggle}
              size="lg"
              backdrop="static"
            >
              <ModalHeader
                style={{ fontSize: "1.5rem" }}
                toggle={toggle}
                close={closeBtn}
              >
                <FontAwesomeIcon icon="utensils" />
                &nbsp;&nbsp;
                {id === 0
                  ? intl.formatMessage({ id: "AddAFood" })
                  : intl.formatMessage({ id: "foodUpdate" })}
              </ModalHeader>
              <ModalBody>
                {loader ? (
                  <div className="text-center py-5">
                    <div className="text-center py-5">
                      <div className="text-center py-5">
                        <Spinner
                          thickness="4px"
                          emptyColor="gray.200"
                          color="green.500"
                          size="xl"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <FormGroup row>
                      <Label for="photo" sm={3}>
                        Photo *
                        <br />
                        <span className="text-muted">(Recommandée)</span>
                      </Label>
                      { currentUser.role != "underrestaurant" ? (
                        <Col
                          sm={3}
                          {...getRootProps({
                            className: "wazi-dropzone text-center",
                          })}
                        >
                          <input
                            {...getInputProps()}
                            type="file"
                            disabled={currentUser.role == "underrestaurant" }
                            name="photo"
                            id="photo"
                          />
                            

                              <FormText className="text-center" color="muted" disabled={currentUser.role == "underrestaurant" }>
                                {intl.formatMessage({ id: "searchOrDropPhoto" })}
                              </FormText>
                            

                        </Col>
                        ) : ("")
                      }
                      {files.length > 0 ? (
                        <Col sm={3} className="thumbs-container">
                          {thumbs}
                        </Col>
                      ) : (
                        <>
                          {image ? (
                            <Col sm={3} className="thumbs-container">
                              <div className="thumbs">
                                <div className="thumbs-inner">
                                  <img src={image} alt="WaziEats" />
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FormGroup>
                    <FormGroup row>
                      <Label for="name" sm={3}>
                        {intl.formatMessage({ id: "nameOfTheFood" })}
                      </Label>
                      <Col sm={9}>
                        <Input
                          value={name}
                          invalid={vName}
                          disabled={currentUser.role == "underrestaurant" }
                          onChange={handleChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder={intl.formatMessage({
                            id: "enterNameOfFood",
                          })}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="description" sm={3}>
                        {intl.formatMessage({ id: "descriptionOnTheFood" })}
                      </Label>
                      <Col sm={9}>
                        <Input
                          value={description}
                          onChange={handleChange}
                          disabled={currentUser.role == "underrestaurant" }
                          type="textarea"
                          name="description"
                          id="description"
                          placeholder={intl.formatMessage({
                            id: "enterTheDescriptionFood",
                          })}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="type" sm={3}>
                        {intl.formatMessage({ id: "typeOfFood" })}
                      </Label>
                      <Col sm={9}>
                        <Input
                          // value={type}
                          
                          onChange={handleChange}
                          type="select"
                          invalid={vType}
                          disabled={currentUser.role == "underrestaurant"}
                          name="type"
                          id="type"
                        >
                          {type === "" ||
                          type === null ||
                          type === undefined ? (
                            <option value="">
                              {" "}
                              {intl.formatMessage({ id: "selectAType" })}
                            </option>
                          ) : (
                            [
                              types.find(
                                (obj) =>
                                  obj.name === type ||
                                  `${obj.id}` === type ||
                                  obj.id === type
                              ),
                            ].map((type, _) => (
                              <option value={`${type.id}`}>{type.name}</option>
                            ))[0]
                          )}
                          {types.map((type, _) => (
                            <option value={type.id}>{type.name}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="type" sm={3}>
                        {intl.formatMessage({ id: "subFoodType" })}
                      </Label>
                      <Col sm={9}>
                        <Input
                          // value={type}
                          onChange={handleChange}
                          type="select"
                          invalid={vType}
                          disabled={currentUser.role == "underrestaurant"}
                          name="subtype"
                          id="subtype"
                        >
                          {subType === "" ||
                          subType === null ||
                          subType === undefined ? (
                            <option value="">
                              {intl.formatMessage({ id: "selectSubFoodType" })}
                            </option>
                          ) : (
                            [
                              subTypes.find(
                                (obj) =>
                                  obj.name === subType ||
                                  `${obj.id}` === subType ||
                                  obj.id === subType
                              ),
                            ].map((subtype, _) => (
                              <option value={`${subtype.id}`}>
                                {subtype.name}
                              </option>
                            ))[0]
                          )}
                          {subTypes.map((subtype, _) => (
                            <option value={subtype.id}>{subtype.name}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                    {currentUser.role != "underrestaurant" ? (
                      <FormGroup row>
                      <Label for="sousrestaurant" sm={3}>
                        {intl.formatMessage({ id: "subPartner" })}
                      </Label>
                      <Col sm={9}>
                        <Multiselect
                          allowCreate="onFilter"
                          value={resaurants}
                          data={resaurantsList}
                          onChange={(value) => intSousResto(value)}
                          //dataKey="id"
                          textField="name"
                          name="sousrestaurant"
                          id="sousrestaurant"
                        />
                      </Col>
                    </FormGroup>
                    ) : ("")}

                    {resaurants.length > 0 && currentUser.role != "underrestaurant" ? (
                      <FormGroup row>
                        <Label for="sousrestaurant" sm={3}>
                          {intl.formatMessage({ id: "selectSubPartner" })}
                        </Label>
                        <Col sm={9}>
                          <HStack spacing="24px" direction="row" wrap="wrap">
                            {resaurants.map((item, id) => {
                              return (
                                <Checkbox
                                  isChecked={
                                    selectSousResto === item.id.toString()
                                      ? true
                                      : false
                                  }
                                  colorScheme="red"
                                  onChange={(value) =>
                                    setDetailSousPlat(item.id)
                                  }
                                >
                                  {item.name}
                                </Checkbox>
                              );
                            })}
                          </HStack>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {resaurants.length > 0 && selectSousResto.length > 0 ? (
                      selectSousRestoDetail[selectSousResto] ? (
                        <div className="detail-plat">
                          <FormGroup row>
                            <Label for="price" sm={3}>
                              {intl.formatMessage({ id: "priceOfFoodFCFA" })}
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].price
                                }
                                invalid={vPrice}
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="number"
                                name="price"
                                id="price"
                                placeholder={intl.formatMessage({
                                  id: "enterPriceOfFood",
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="price" sm={3}>
                              {intl.formatMessage({
                                id: "purchasePriceOfFoodFCFA",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .priceAchat
                                }
                                invalid={vPrice}
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="number"
                                name="price_achat"
                                id="price"
                                placeholder={intl.formatMessage({
                                  id: "enterPurchasePriceOfFood",
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="nutritional" sm={3}>
                              {intl.formatMessage({
                                id: "nutritionalValue",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .nutritional
                                }
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="text"
                                name="nutritional"
                                id="nutritional"
                                placeholder={intl.formatMessage({
                                  id: "enterNutritionalValueOfFood",
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="origin" sm={3}>
                              cookingTimeInMinutes
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].timer
                                }
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                step="2"
                                type="number"
                                name="time"
                                id="time"
                                placeholder={intl.formatMessage({
                                  id: "cookingTimeOfFoodInMinutes",
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="ingredients" sm={3}>
                              {intl.formatMessage({
                                id: "foodIngredient",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Multiselect
                                allowCreate="onFilter"
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .ingredient
                                }
                                data={ingredients}
                                onChange={(e) =>
                                  handleChangeSousRestoMul(
                                    e,
                                    "ingredients",
                                    selectSousResto
                                  )
                                }
                                onCreate={handleCreate}
                                //dataKey="id"
                                textField="name"
                                name="ingredients"
                                id="ingredients"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="obstacles" sm={3}>
                              {intl.formatMessage({
                                id: "proteins",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Multiselect
                                allowCreate="onFilter"
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .obstacle
                                }
                                data={obstacles}
                                onChange={(e) =>
                                  handleChangeSousRestoMul(
                                    e,
                                    "obstacles",
                                    selectSousResto
                                  )
                                }
                                onCreate={handleCreateObstacles}
                                //dataKey="id"
                                textField="value"
                                name="obstacles"
                                id="obstacles"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="complements" sm={3}>
                              {intl.formatMessage({
                                id: "side",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Multiselect
                                allowCreate="onFilter"
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .complement
                                }
                                data={complements}
                                onChange={(e) =>
                                  handleChangeSousRestoMul(
                                    e,
                                    "complements",
                                    selectSousResto
                                  )
                                }
                                onCreate={handleCreateComplements}
                                //dataKey="id"
                                textField="value"
                                name="complements"
                                id="complements"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="Gamelle" sm={3}>
                              {intl.formatMessage({
                                id: "container",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].gamelle
                                }
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="select"
                                // invalid={vType}
                                // disabled={dType}
                                name="gamelle"
                                id="gamelle"
                              >
                                {gamelle === "" ||
                                gamelle === null ||
                                gamelle === undefined ? (
                                  <option value="">
                                    {intl.formatMessage({
                                      id: "selectAContainer",
                                    })}
                                  </option>
                                ) : (
                                  [gamelles.find((obj) => obj === gamelle)].map(
                                    (gamelle, _) => (
                                      <option value={`${gamelle}`}>
                                        {gamelle}
                                      </option>
                                    )
                                  )[0]
                                )}
                                {gamelles.map((gamelle, _) => (
                                  <option value={gamelle}>{gamelle}</option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="suggestions" sm={3}>
                              Suggestion
                            </Label>
                            <Col sm={9}>
                              <Multiselect
                                allowCreate="onFilter"
                                value={
                                  selectSousRestoDetail[selectSousResto]
                                    .suggestion
                                }
                                data={data}
                                onChange={(e) =>
                                  handleChangeSousRestoMul(
                                    e,
                                    "Suggestion",
                                    selectSousResto
                                  )
                                }
                                // onCreate={handleCreate}
                                //dataKey="id"
                                textField="name"
                                name="suggestions"
                                id="suggestions"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label sm={2}>
                              {intl.formatMessage({
                                id: "publicationDay",
                              })}
                            </Label>
                            <Col sm={2}>
                              <Multiselect
                                value={
                                  selectSousRestoDetail[selectSousResto].day
                                }
                                invalid={vDay}
                                onChange={(e) =>
                                  handleChangeSousRestoMul(
                                    e,
                                    "publications",
                                    selectSousResto
                                  )
                                }
                                name="day"
                                style={{
                                  backgroundColor: "#0ab44e",
                                  color: "#fff",
                                }}
                                id="day"
                                data={[
                                  intl.formatMessage({ id: "lundi" }),
                                  intl.formatMessage({ id: "mardi" }),
                                  intl.formatMessage({ id: "mercredi" }),
                                  intl.formatMessage({ id: "jeudi" }),
                                  intl.formatMessage({ id: "vendredi" }),
                                  intl.formatMessage({ id: "samedi" }),
                                  intl.formatMessage({ id: "dimanche" }),
                                ]}
                              />
                            </Col>
                            <Col sm={3}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].sDate
                                }
                                invalid={vDate}
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="date"
                                name="start"
                                id="start"
                                placeholder="Début"
                              />
                              <span>
                                {intl.formatMessage({
                                  id: "startDate",
                                })}
                              </span>
                            </Col>
                            <Col sm={3}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].eDate
                                }
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="date"
                                name="end"
                                id="end"
                                placeholder={intl.formatMessage({
                                  id: "end",
                                })}
                              />
                              <span>
                                {intl.formatMessage({
                                  id: "endDate",
                                })}
                              </span>
                            </Col>
                            <Col sm={2}>
                              <Input
                                value={
                                  selectSousRestoDetail[selectSousResto].repeat
                                }
                                onChange={(e) =>
                                  handleChangeSousResto(e, selectSousResto)
                                }
                                type="select"
                                name="repeat"
                                style={{
                                  backgroundColor: "#0ab44e",
                                  color: "#fff",
                                }}
                                id="repeat"
                              >
                                <option value="">
                                  {intl.formatMessage({
                                    id: "repeat",
                                  })}
                                </option>
                                <option value="true">
                                  {intl.formatMessage({
                                    id: "yes",
                                  })}
                                </option>
                                <option value="false">
                                  {intl.formatMessage({
                                    id: "no",
                                  })}
                                </option>
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      <div className="detail-plat">
                        {currentUser.role != "underrestaurant" ? (
                          <FormGroup row>
                            <Label for="price" sm={3}>
                              {intl.formatMessage({
                                id: "priceOfFoodFCFA",
                              })}
                            </Label>
                            <Col sm={9}>
                              <Input
                                value={price}
                                invalid={vPrice}
                                onChange={handleChange}
                                disabled={currentUser.role == "underrestaurant"}
                                type="number"
                                name="price"
                                id="price"
                                placeholder={intl.formatMessage({
                                  id: "enterPriceOfFood",
                                })}
                              />
                            </Col>
                          </FormGroup>
                        ) : ("")}
                        <FormGroup row>
                          <Label for="price" sm={3}>
                            {intl.formatMessage({
                              id: "purchasePriceOfFoodFCFA",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Input
                              value={priceAchat}
                              invalid={vPrice}
                              onChange={handleChange}
                              type="number"
                              disabled={currentUser.role == "underrestaurant"}
                              name="price_achat"
                              id="price"
                              placeholder={intl.formatMessage({
                                id: "enterPurchasePriceOfFood",
                              })}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label for="nutritional" sm={3}>
                            {intl.formatMessage({
                              id: "nutritionalValue",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Input
                              value={nutritional}
                              onChange={handleChange}
                              type="text"
                              name="nutritional"
                              id="nutritional"
                              placeholder={intl.formatMessage({
                                id: "enterNutritionalValueOfFood",
                              })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="origin" sm={3}>
                            {intl.formatMessage({
                              id: "cookingTimeInMinutes",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Input
                              value={timer}
                              onChange={handleChange}
                              step="2"
                              type="number"
                              name="time"
                              id="time"
                              placeholder={intl.formatMessage({
                                id: "cookingTimeOfFoodInMinutes",
                              })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="ingredients" sm={3}>
                            {intl.formatMessage({
                              id: "foodIngredient",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Multiselect
                              allowCreate="onFilter"
                              value={ingredient}
                              data={ingredients}
                              onChange={(value) => setIngredient(value)}
                              onCreate={handleCreate}
                              //dataKey="id"
                              textField="name"
                              name="ingredients"
                              id="ingredients"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="obstacles" sm={3}>
                            {intl.formatMessage({
                              id: "proteins",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Multiselect
                              allowCreate="onFilter"
                              value={obstacle}
                              data={obstacles}
                              onChange={(value) => setObstacle(value)}
                              onCreate={handleCreateObstacles}
                              //dataKey="id"
                              textField="value"
                              name="obstacles"
                              id="obstacles"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="complements" sm={3}>
                            Complements
                          </Label>
                          <Col sm={9}>
                            <Multiselect
                              allowCreate="onFilter"
                              value={complement}
                              data={complements}
                              onChange={(value) => setComplement(value)}
                              onCreate={handleCreateComplements}
                              //dataKey="id"
                              textField="value"
                              name="complements"
                              id="complements"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="Gamelle" sm={3}>
                            {intl.formatMessage({
                              id: "container",
                            })}
                          </Label>
                          <Col sm={9}>
                            <Input
                              value={gamelle}
                              onChange={handleChange}
                              type="select"
                              // invalid={vType}
                              // disabled={dType}
                              disabled={currentUser.role == "underrestaurant"}
                              name="gamelle"
                              id="gamelle"
                            >
                              {gamelle === "" ||
                              gamelle === null ||
                              gamelle === undefined ? (
                                <option value="">
                                  {intl.formatMessage({
                                    id: "selectAContainer",
                                  })}
                                </option>
                              ) : (
                                [gamelles.find((obj) => obj === gamelle)].map(
                                  (gamelle, _) => (
                                    <option value={`${gamelle}`}>
                                      {gamelle}
                                    </option>
                                  )
                                )[0]
                              )}
                              {gamelles.map((gamelle, _) => (
                                <option value={gamelle}>{gamelle}</option>
                              ))}
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="suggestions" sm={3}>
                            Suggestion
                          </Label>
                          <Col sm={9}>
                            <Multiselect
                              allowCreate="onFilter"
                              value={suggestion}
                              data={data}
                              onChange={(value) => setSuggestion(value)}
                              // onCreate={handleCreate}
                              //dataKey="id"
                              textField="name"
                              name="suggestions"
                              id="suggestions"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={2}>
                            {intl.formatMessage({
                              id: "publicationDay",
                            })}
                          </Label>
                          <Col sm={2}>
                            <Multiselect
                              value={day}
                              invalid={vDay}
                              onChange={(value) => setDay(value)}
                              name="day"
                              style={{
                                backgroundColor: "#0ab44e",
                                color: "#fff",
                              }}
                              id="day"
                              data={[
                                intl.formatMessage({ id: "lundi" }),
                                intl.formatMessage({ id: "mardi" }),
                                intl.formatMessage({ id: "mercredi" }),
                                intl.formatMessage({ id: "jeudi" }),
                                intl.formatMessage({ id: "vendredi" }),
                                intl.formatMessage({ id: "samedi" }),
                                intl.formatMessage({ id: "dimanche" }),
                              ]}
                            />
                          </Col>
                          <Col sm={3}>
                            <Input
                              value={sDate}
                              invalid={vDate}
                              onChange={handleChange}
                              type="date"
                              name="start"
                              id="start"
                              placeholder="Début"
                            />
                            <span>
                              {intl.formatMessage({
                                id: "startDay",
                              })}
                            </span>
                          </Col>
                          <Col sm={3}>
                            <Input
                              value={eDate}
                              onChange={handleChange}
                              type="date"
                              name="end"
                              id="end"
                              placeholder="Fin"
                            />
                            <span>
                              {intl.formatMessage({
                                id: "endDay",
                              })}
                            </span>
                          </Col>
                          <Col sm={2}>
                            <Input
                              value={repeat}
                              onChange={handleChange}
                              type="select"
                              name="repeat"
                              style={{
                                backgroundColor: "#0ab44e",
                                color: "#fff",
                              }}
                              id="repeat"
                            >
                              <option value="">
                                {intl.formatMessage({
                                  id: "repeat",
                                })}
                              </option>
                              <option value="true">
                                {intl.formatMessage({
                                  id: "yes",
                                })}
                              </option>
                              <option value="false">
                                {intl.formatMessage({
                                  id: "no",
                                })}
                              </option>
                            </Input>
                          </Col>
                        </FormGroup>
                      </div>
                    )}

                    <FormGroup>
                      <Input
                        value={id}
                        onChange={handleChange}
                        type="hidden"
                        name="id"
                        id="id"
                      />
                    </FormGroup>

                    <FormGroup check row className="mt-5">
                      <Col sm={{ size: 2, offset: 10 }}>
                        <Button type="submit" block className="wazi-btn">
                          {id === 0
                            ? intl.formatMessage({ id: "add" })
                            : intl.formatMessage({ id: "update" })}
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </ModalBody>
            </Modal>
          </Col>
        </Row>

        <LoadingOverlay
          tag="div"
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active={loading} type="ball-pulse-rise" />}
        >
          <Row>
            {data.map((item) => {
              //console.log(item.id);
              return (
                <Col key={`plate-${item.id}`} md="12" lg="6" xl="3">
                  <Card className="wazi-card-item card-shadow-primary card-border text-white mb-3 product-bg" styles={{opacity: 0.5} ? item.is_active === false : {opacity: 1}}>
                    <div className="btn-actions-pane-right actions-icon-btn">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          className="btn-icon btn-icon-only"
                          color="link"
                        >
                          <FontAwesomeIcon
                            icon="ellipsis-v"
                            className="btn-icon-wrapper"
                          />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                          <DropdownItem onClick={(e) => edit(item)}>
                            <i className="dropdown-icon lnr-pencil"> </i>
                            <span>{intl.formatMessage({ id: "modify" })}</span>
                          </DropdownItem>
                          <DropdownItem onClick={(e) => handleDelete(item)}>
                            <i className="dropdown-icon lnr-trash"> </i>
                            <span>{intl.formatMessage({ id: "delete" })}</span>
                          </DropdownItem>
                          {
                            currentUser.role != "underrestaurant" ? ( 
                              item.is_active ? (
                                <DropdownItem
                                  onClick={(e) => handleDeactivate(item)}
                                >
                                  <i className="dropdown-icon lnr-eye"> </i>
                                  <span>
                                    {intl.formatMessage({ id: "deactivate" })}
                                  </span>
                                </DropdownItem>
                              ) : (
                                <DropdownItem onClick={(e) => handleActivate(item)}>
                                  <i className="dropdown-icon lnr-eye"> </i>
                                  <span>Activate</span>
                                </DropdownItem>
                              )
                            ) : ("")
                          }
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                    <Item
                      name={item.name}
                      price={Math.ceil(currentUser.role != "underrestaurant" ? item.price : item.purchase_price)}
                      image={item.foodPicture}
                    />
                    <div className="item-detail">
                      <h5
                        style={{
                          position: "absolute",
                          bottom: "50%",
                          left: "35%",
                        }}
                      >
                        {intl.formatMessage({ id: "foodDetails" })}
                      </h5>
                    </div>
                  </Card>
                </Col>
              );
            })}

          </Row>
        </LoadingOverlay>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button
            className="btn-icon btn-icon-only ps-2  wazi-btn"
            onClick={handlePrevious} disabled={currentPage === 1}
          >
            Précédent
          </Button>
          <span style={{ margin: "0 10px" }}>
            Page {currentPage} sur {totalPages}
          </span>
          <Button
            className="btn-icon btn-icon-only ps-2  wazi-btn"
            onClick={handleNext} disabled={currentPage === totalPages}
          >
            Suivant
          </Button>
        </div>
      </div>
    </Base>
  );
}

export default Plates;
