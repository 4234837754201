import { PSOUSREST } from "../../services/config";
import { useIntl } from "react-intl";

export const useMainNav = () => {
  const intl = useIntl();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const sousRestItem = {
    icon: "pe-7s-shopbag",
    label: intl.formatMessage({ id: "subPartner" }),
    to: "/restaurants",
  };

  const shops = {
    icon: "pe-7s-network",
    label: intl.formatMessage({ id: "shops" }),
    to: "/shops",
  };

  const item = [
    {
      icon: "pe-7s-graph",
      label: intl.formatMessage({ id: "dashboard" }),
      to: "/",
    },
    {
      icon: "pe-7s-note2",
      label: intl.formatMessage({ id: "orders" }),
      to: "/orders",
    },
    {
      icon: "pe-7s-browser",
      label: intl.formatMessage({ id: "onlineRestaurant" }),
      content: [
        {
          label: intl.formatMessage({ id: "foodManagement" }),
          to: "/plates",
        },
        {
          label: intl.formatMessage({ id: "drinksManagement" }),
          to: "/drinks",
        },
        {
          label: intl.formatMessage({ id: "menusAndPromotions" }),
          to: "/menus",
        },
      ],
    },
  ];
  currentUser && currentUser.restaurant == PSOUSREST && currentUser.role !='underrestaurant'
    ? item.push(sousRestItem)
    : true;

    currentUser && currentUser.restaurant == PSOUSREST && currentUser.role !='underrestaurant'
    ? item.push(shops)
    : true;

  item.push({
    icon: "pe-7s-help1",
    label: intl.formatMessage({ id: "help" }),
    to: "https://wa.me/message/LM3P6353YUZ6N1",
  });

  return item;
};
