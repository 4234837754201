import React, {
    KeyboardEventHandler,
    Fragment,
    useEffect,
    useState,
    useRef,
  } from "react";
  import axios from "axios";
  import Table from "react-bootstrap/Table";
  import Base from "../Base";
  import Select from "react-select";
  import SweetAlert from "react-bootstrap-sweetalert";
  import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    FormFeedback,
    FormText,
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";
  import { Loader } from "react-loaders";
  import LoadingOverlay from "react-loading-overlay-ts";
  import { useSelector } from "react-redux";
  import { isLoggedIn } from "../../utils/selectors";
  import { Redirect } from "react-router-dom";
  import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Image,
    Box,
  } from "@chakra-ui/react";
  import { isMobile } from 'react-device-detect';
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import {
    faArrowLeft,
    faArrowRight,
    faStore,
    faStoreAlt,
    faFileContract,
    faUserCog,
    faMapPin,
    faGlobeAfrica,
    faLocationDot,
    faEyeSlash,
    faEye,
    faUserAlt,
    faPhoneAlt,
    faXmarkCircle,
    faTimes,
    faTrash,
    faUtensils,
    faPenToSquare,
  } from "@fortawesome/free-solid-svg-icons";
  import Item from "../../components/Item";
  import { InputGroup, InputGroupText, Input } from "reactstrap";
  import CreatableSelect from "react-select/creatable";
  import { useDropzone } from "react-dropzone";
  import Dropzone from "react-dropzone";
  // import {
  //     ModalOverlay,
  //     ModalContent,
  //     ModalHeader,
  //     ModalFooter,
  //     ModalBody,
  //     ModalCloseButton,
  // } from "@chakra-ui/react";
  import { useDisclosure } from "@chakra-ui/react";
  import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";
  import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/bootstrap.css";
  // import { isValidPhoneNumber } from "react-phone-number-input";
  import foodService from "../../services/foodService";
  import drinkService from "../../services/drinkService";
  import { Spinner } from "@chakra-ui/react";
  import mainService from "../../services/mainService";
  import swal from "sweetalert";
  import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
  // import { ClearButton } from "react-bootstrap-typeahead";
  import { event } from "jquery";
  import { isValidPhoneNumber } from "libphonenumber-js";
  import AppModal from "../../components/AppModal";
  import isEmail from "validator/es/lib/isEmail";
  import TermsModal from "../../components/TermCondition";
  import ModalBodyComponent from "../Profil/Map";
  import FacebookPlayer from "react-player/facebook";
  import { useIntl } from "react-intl";
  
  const components = {
    DropdownIndicator: null,
  };
  
  const createOption = (label) => ({
    label,
    value: label,
  });
  
  const lib = ["places"];
  
  export default function Shops() {
    const intl = useIntl();
  
    const [id, setId] = useState(0);
    const [confirm, setConfirm] = useState("");
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [loading, setLoading] = useState(false);
  
    const [rname, setRname] = useState("");
    const [vrname, setVRname] = useState(false);
  
    const [name, setName] = useState("");
    const [sname, setSname] = useState("");
    const [vname, setVname] = useState(false);
  
    const [open, setOpen] = useState("08:00:00");
    const [close, setClose] = useState("20:00:00");
    const [oHour, setOhour] = useState(false);
    const [cHour, setChour] = useState(false);
  
    const [category, setCategory] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [resaurantsList, setResaurantsList] = useState([]);
  
    const [long, setLong] = useState();
    const [lat, setLat] = useState();
    const [address, setAddress] = useState("");
    const [showAlert, setShowAlert] = useState(false);
  
    console.log(long, lat);
  
    // let long = 0.0;
    // let lat = 0.0;
    // let address = "";
  
    const [tabIndex, setTabIndex] = useState(0);
    const [profile, setProfile] = useState([]);
    const [picture, setPicture] = useState([]);
    const [profileError, setProfileError] = useState(false);
    const [cni, setCNI] = useState([]);
    const [rccm, setRCCM] = useState([]);
    const [immatriculation, setImmatriculation] = useState([]);
    const [image, setImage] = useState([]);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const user = localStorage.getItem("user");
    const mainUser = JSON.parse(user);
    const token = mainUser.token;
    const [restaurateur, setRestaurateur] = useState([]);
    const [modal2, setModal2] = useState(false);
  
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [],
      },
      multiple: false,
      onDrop: (acceptedFiles) => {
        setProfile(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
  
    const removeFiles = (file) => () => {
      const newProfile = [...profile];
      newProfile.splice(profile, 1);
      setProfile(newProfile);
      event.preventDefault;
    };
  
    const removePicture = (file) => () => {
      const newPicture = [...picture];
      newPicture.splice(picture.indexOf(file), 1);
      setPicture(newPicture);
      event.preventDefault;
    };
    const toggleShow = () => setShow(!showAlert);
  
    useEffect(() => {
      return () => profile.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [profile]);
  
    useEffect(async () => {
      setLoading(true);
      await mainService
        .getTypeShops()
        .then((response) => {
          setCategoryList(response.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
  
      await mainService
        .getShops(token)
        .then((response) => {
          setResaurantsList(response.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }, []);
  
    const isConnected = useSelector(isLoggedIn);
  
    const getValidUrl = (url = "") => {
      if (url === "") {
        return url;
      } else {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");
  
        if (/^(:\/\/)/.test(newUrl)) {
          return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
          return `http://${newUrl}`;
        }
  
        return newUrl;
      }
    };
  
    const inputRef = useRef();
    const onAddressesChanged = () => {
      const [place] = inputRef.current.getPlaces();
      setAddress(place.formatted_address);
      setLong(place.geometry.location.lng());
      setLat(place.geometry.location.lat());
      console.log(place);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (rname === "") {
        setVRname(true);
        setTabIndex(0);
      } else if (phone === "" || !isValidPhoneNumber(phone, "CM")) {
        setVRname(false);
        setPhoneError(true);
        setTabIndex(2);
      } else if (name === "") {
        setVRname(false);
        setPhoneError(false);
        setVname(true);
        setTabIndex(2);
      } else if (phone2 === "" || !isValidPhoneNumber(phone2, "CM")) {
        setVRname(false);
        setPhoneError(false);
        setVname(false);
        setPhone2Error(true);
      } else {
        if (terms) {
          setPhoneError(false);
          setPhone2Error(false);
          setVRname(false);
          setVname(false);
          setProfileError(false);
  
          let formData = new FormData();
  
          formData.append("name", rname);
  
          if (address !== undefined && address !== "") {
            formData.append("address", address);
          }
          if (lat !== undefined && lat !== null) {
            formData.append("location", lat);
          }
          if (long !== undefined && long !== null) {
            formData.append("location", long);
          }
  
          if (picture && picture[0]) {
            for (let i = 0; i < Math.max(picture.length, 3); i++) {
              if (i < picture.length) {
                formData.append("picture_restaurant[" + i + "]image", picture[i]);
              } else {
                formData.append("picture_restaurant[" + i + "]image", profile[0]);
              }
            }
          }
          if (email !== "" || isEmail(email)) {
            formData.append("email", email);
          }
          formData.append("phone", phone);
          formData.append("whatsapp_number", phone2);
          formData.append("first_name", sname);
          formData.append("last_name", name);
          if (profile && profile[0]) {
            formData.append("profile_picture", profile[0]);
          }
          if (category && Number(category)) {
            formData.append("restaurantCategory", category);
          }
          formData.append("role", "restaurant");
          formData.append("lieu_dit", lieuDit);
  
          if (web !== "") {
            formData.append("internet_site", getValidUrl(web));
          }
          for (let i = 0; i < value.length; i++) {
            formData.append(
              "social_network_link[" + i + "]",
              getValidUrl(value[i].value)
            );
          }
  
          // Display the key/value pairs
          for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }
          try {
            let response = {};
            if (editStatus === 0) {
              response = await mainService.createSouseRestaurant(formData, token);
            } else {
              response = await mainService.updateSousRestaurant(
                formData,
                token,
                id
              );
            }
  
            await mainService
              .getSouseRestaurant(token)
              .then((response) => {
                setResaurantsList(response.data);
              })
              .catch((err) => {
                console.log(err);
              });
  
            console.log("Server RESPONSE", response);
            setLoading(false);
            setModal(!modal);
            // swal(
            //     "Votre demande de creation de compte est en cours de traitement",
            //     "success"
            // ).then(() => {
            //     window.location.href = "/";
            // });
          } catch (e) {
            setLoading(false);
            console.log("Server ERROR", e);
            let error = e.response.data.message;
            console.log(error);
            if (error !== undefined) {
              if (error && error.includes("email")) {
                swal(
                  "Erreur",
                  "Un compte a été déjà enregistré avec cet email",
                  "error"
                );
              } else if (error && error.includes("name")) {
                swal(
                  "Erreur",
                  "Un compte a été déjà enregistré avec ce nom",
                  "error"
                );
              } else if (error && error.includes("phone")) {
                swal(
                  "Erreur",
                  "Un compte a été déjà enregistré avec ce numéro de téléphone",
                  "error"
                );
              } else if (error && error.includes("whatsapp_number")) {
                swal(
                  "Erreur",
                  "Un compte a été déjà enregistré avec ce numéro whatsapp",
                  "error"
                );
              } else if (error && error.includes("profile_picture")) {
                swal(
                  "Erreur",
                  "La photo de profil du restaurant est requise pour procéder",
                  "error"
                );
              } else if (error && error.includes("picture_restaurant")) {
                swal(
                  "Erreur",
                  "Une photo du restaurant est requise pour procéder",
                  "error"
                );
              } else if (error && error.includes("picture")) {
                swal(
                  "Erreur",
                  "La photo de profil de l'administrateur est requise pour procéder",
                  "error"
                );
              } else if (error && error.includes("internet_site")) {
                swal(
                  "Erreur",
                  "Un compte a été déjà enregistré avec ce site web",
                  "error"
                );
              } else if (error && error.includes("social_network_link")) {
                swal(
                  "Erreur",
                  "Un lien de réseau social est requis pour procéder",
                  "error"
                );
              } else {
                swal(
                  "Erreur",
                  "Veuillez saisir tous les champs obligatoires",
                  "error"
                );
              }
            } else {
              if (
                Math.floor(e.response.status / 400) === 1 ||
                Math.floor(e.response.status / 500) === 1
              ) {
                swal(
                  "Erreur",
                  e.response.data.detail ||
                    e.response.message ||
                    "Veuillez saisir tous les champs obligatoires",
                  "error"
                );
              } else {
                swal(
                  "Erreur",
                  "Veuillez saisir tous les champs obligatoires",
                  "error"
                );
              }
            }
          }
        } else {
          swal("Erreur", "Terms and Conditions Not Accepted", "error");
        }
      }
  
      setLoading(false);
    };
  
    const handleChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
  
      switch (name) {
        case "password":
          setPassword(value);
          break;
        case "confirm":
          setConfirm(value);
          break;
        case "name":
          setName(value);
          break;
        case "immatriculation":
          setImmatriculation(value);
          break;
        case "sname":
          setSname(value);
          break;
        case "rname":
          setRname(value);
          break;
        case "email":
          setEmail(value);
          break;
        case "lieu-dit":
          setLieuDit(value);
          break;
        case "opening":
          setOpen(value);
          break;
        case "closing":
          setClose(value);
          break;
        case "website":
          setWeb(value);
          break;
        case "category":
          setCategory(value);
          break;
        default:
          break;
      }
    };
    const handleDelete = (restaurant) => {
      toggleConfirmDelete(restaurant);
    };
  
    const toggleConfirmDelete = (p) => {
      swal({
        text: "Confirmer la suppression du Restaurant",
        title: "Suppression",
        icon: "warning",
        buttons: ["Non", "Oui"],
      }).then((value) => {
        if (value) {
          setLoading(true);
          mainService
            .removeSouseRestaurant(p.id, token)
            .then(() => {
              swal("Restaurant supprimé avec succès", {
                icon: "success",
              }).then(() => {
                mainService
                  .getSouseRestaurant(token)
                  .then((response) => {
                    setResaurantsList(response.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                setLoading(true);
              });
            })
            .catch((err) => {
              console.log(err);
              swal("Erreur lors du traitement de la requete", {
                icon: "error",
              });
              setLoading(true);
            });
        }
      });
    };
  
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
  
    const [email, setEmail] = useState("");
  
    const [terms, setTerms] = useState(true);
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [pError, setPhoneError] = useState(false);
    const [p2Error, setPhone2Error] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [lieuDit, setLieuDit] = useState("");
  
    const [fCategory, setFcategory] = useState([1]);
    const [fType, setFtype] = useState([1]);
    const [dCategory, setDcategory] = useState([1]);
    const [dType, setDtype] = useState([1]);
    const [module, setModule] = useState([1]);
    const [channel, setChannel] = useState(1);
  
    const [web, setWeb] = useState("");
    const [editStatus, setEditStatus] = useState(0);
    const [vuePofilePicture, setVuePofilePicture] = useState("");
    const [vueRestaurantPicture, setVueRestaurantPicture] = useState([]);
    const [social, setSocial] = useState("");
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [search, setSearch] = useState("");
  
    const [value, setValue] = React.useState([]);
  
    const handleKeyDown = (event) => {
      if (!social) return;
      switch (event.key) {
        case "Enter":
        case "Tab":
          setValue((prev) => [...prev, createOption(social)]);
          setSocial("");
          event.preventDefault();
      }
    };
    const [isOpen, setIsOpen] = useState(false);
  
    const handleOpenModal = () => {
      setIsOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsOpen(false);
    };
  
    const handleSaveCoordinates = (address, latitude, longitude) => {
      setAddress(address);
      setLat(latitude);
      setLong(longitude);
    };
  
    const getPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setLat(latitude);
            setLong(longitude);
            await getAddressFromCoordinates(latitude, longitude);
            // showPosition(position)
          },
          (error) => {
            console.error(error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
  
    const getAddressFromCoordinates = async (latitude, longitude) => {
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKEY}`;
  
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
  
        if (data.results && data.results.length > 0) {
          setAddress(data.results[0].formatted_address);
        } else {
          setAddress("Address not found.");
          console.log("Address not found");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };
  
    const handleChangeSearch = (event) => {
      setSearch(event.target.value)
  
      // Effacer le timer précédent
      if (debounceTimeout) {
          clearTimeout(debounceTimeout);
      }
      // Définir un nouveau timer
      setDebounceTimeout(setTimeout( async() => {
        setLoading(true);
        await mainService
          .getShops(token,search)
          .then((response) => {
            setResaurantsList(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(false);
      }, 500));
    }
  
    // Nettoyer le timer lors du démontage du composant
     useEffect(() => {
      return () => {
          if (debounceTimeout) {
              clearTimeout(debounceTimeout);
          }
      };
    }, [debounceTimeout])
  
    //const [ filter, setFilter ] = useState('')
    const [modal, setModal] = useState(false);
  
    if (!isConnected) {
      return <Navigate replace to="/login" />;
    }
  
    const toggle = () => {
      // resetForm();
      setEditStatus(0);
      setRname("");
      setName("");
      setSname("");
      setPhone("");
      setPhone2("");
      setEmail("");
      setCategory("null");
      setLat(4.0511);
      setLong(9.7678);
      setWeb("");
      setAddress("");
      setSocial("");
      setModal(!modal);
      setLieuDit("")
    };
  
    const handleFilter = (event) => {
      console.log(event);
    };
  
    const edit = (restaurant) => {
      setId(restaurant.id);
      setName(restaurant.name);
      setAddress(restaurant.address);
      setEmail(restaurant.email);
      setPhone(restaurant.phone);
      setRestaurateur(restaurant.user_first_name + restaurant.user_last_name);
      setVuePofilePicture(restaurant.profile_picture);
      setLieuDit(restaurant.lieu_dit);
      setVueRestaurantPicture(restaurant.picture_shops);
      setModal2(true);
    };
    const fetchAndSendImage = async (imageUrl) => {
      try {
        const response = await axios.get(imageUrl);
        const imageBlob = await response.blob();
        const imageData = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(imageBlob);
        });
  
        return imageData;
      } catch (error) {
        console.error(
          "Une erreur est survenue lors de la récupération de l'image :",
          error
        );
      }
    };
  
    const editToggle = (restaurant) => {
      setEditStatus(1);
      setId(restaurant.id);
      setRname(restaurant.name);
      setName(restaurant.user_last_name);
      setSname(restaurant.user_first_name);
      setPhone(restaurant.phone ? restaurant.phone : "");
      setPhone2(restaurant.whatsapp_number ? restaurant.whatsapp_number : "");
      setEmail(restaurant.email ? restaurant.email : "");
      setCategory(
        restaurant.restaurantCategory.id ? restaurant.restaurantCategory.id : ""
      );
      setLat(restaurant.location ? restaurant.location[0] : 4.0511);
      setLong(restaurant.location ? restaurant.location[1] : 9.7678);
      setWeb(restaurant.internet_site ? restaurant.internet_site : "");
      setAddress(restaurant.address ? restaurant.address : "");
      setSocial(
        restaurant.social_network_link ? restaurant.social_network_link : ""
      );
  
      console.log("hunter debug", restaurant)
      setLieuDit(restaurant.lieu_dit ? restaurant.lieu_dit : "")
  
      // setProfile(fetchAndSendImage(restaurant.user_profile_picture))
      // setPicture(
      //     fetchAndSendImage(restaurant.picture_restaurant).map((file) =>
      //         Object.assign(file, {
      //             preview: URL.createObjectURL(file),
      //         })
      //     )
      // );
  
      setModal(true);
    };
  
    const resetForm = () => {
      setId(0);
      setName("");
    };
  
    const toggleTwo = () => {
      resetForm();
      setModal2(!modal2);
    };
  
    const textFilter = " Afficher par";
    const filters = [
      {
        value: "name",
        label: intl.formatMessage({
          id: "name",
        }),
      },
      {
        value: "district",
        label: intl.formatMessage({
          id: "district",
        }),
      },
    ];
  
    const closeBtn = (
      <FontAwesomeIcon
        className="close"
        onClick={toggle}
        style={{ cursor: "pointer", fontSize: "1.5rem" }}
        icon={faTimes}
      />
    );
  
    const closeBtnTwo = (
      <FontAwesomeIcon
        className="close"
        onClick={toggleTwo}
        style={{ cursor: "pointer", fontSize: "1.5rem" }}
        icon={faTimes}
      />
    );
  
    return (
      <Base>
        <div className="app-inner-layout">
          <div className="app-inner-layout__header-boxed p-0">
            <div className="app-inner-layout__header text-dark bg-white mb-4 d-flex justify-content-between align-content-center">
              <div className="filter-container d-flex justify-content-center align-items-center w-2-4">
                <Select
                  onChange={handleFilter}
                  defaultValue={{
                    label: (
                      <Fragment>
                        <FontAwesomeIcon icon="bars" />
                        {textFilter}
                      </Fragment>
                    ),
                    value: "",
                  }}
                  type="select"
                  name="filter"
                  id="filter"
                  options={filters}
                ></Select>
                <Input
                  type="text"
                  className="search-input ms-4"
                  value={search}
                  name="search"
                  onChange={handleChangeSearch}
                  placeholder={intl.formatMessage({ id: "search" })}
                />
              </div>
              <h1 className="fs-3 pt-2" style={ isMobile ? {fontSize: "1.2em", marginLeft: "10px"} : {}}>Gestion des Shops </h1>
              <Button
                className="btn-icon btn-icon-only pt-0 ps-2 wazi-btn-add float-end wazi-btn"
                disabled="true"
                onClick={toggle}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
          </div>
  
          <Row>
            <SweetAlert
              title={message}
              show={showAlert}
              type="success"
              onConfirm={toggleShow}
            />
            <Col md="12">
              <Modal
                className="wazi-modal"
                isOpen={modal}
                toggle={toggle}
                size="lg"
                backdrop="static"
              >
                <LoadingOverlay
                  tag="div"
                  active={loading}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "#fff",
                      opacity: 0.5,
                    }),
                  }}
                  spinner={<Loader active={loading} type="ball-pulse-rise" />}
                >
                  <ModalHeader
                    style={{ fontSize: "1.5rem" }}
                    toggle={toggle}
                    close={closeBtn}
                  >
                    <FontAwesomeIcon icon="utensils" />
                    &nbsp;&nbsp;
                    {editStatus === 0
                      ? "Ajouter d'un Restaurant"
                      : "Mise à jour d'un Restaurant"}
                  </ModalHeader>
                  <ModalBody>
                    {loader ? (
                      <div className="text-center py-5">
                        <div className="text-center py-5">
                          <div className="text-center py-5">
                            <Spinner
                              thickness="4px"
                              emptyColor="gray.200"
                              color="green.500"
                              size="xl"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Form>
                        <Col
                          lg={12}
                          className="bg-white"
                          style={{ height: "75%" }}
                        >
                          <Row>
                            <Col md={12}>
                              <FormText>
                                <i>All compulsory fields carry a *</i>.
                              </FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormGroup className="position-relative">
                                <Label for="name">Nom du restaurant *</Label>
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    type={"text"}
                                    name="rname"
                                    id="rname"
                                    value={rname}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Nom"
                                    invalid={vrname}
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faStoreAlt}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                                <FormFeedback className={vrname ? "d-block" : ""}>
                                  Nom du restaurant requis
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={12} className="">
                              <FormGroup>
                                <Label for="name">
                                  Nom(s) Administrateur restaurant *
                                </Label>
                                <Row>
                                  <Col>
                                    <InputGroup size="lg" className="mt-1">
                                      <Input
                                        type={"text"}
                                        name="name"
                                        id="name"
                                        value={name}
                                        onChange={handleChange}
                                        style={{
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                        bsSize="lg"
                                        placeholder="Noms"
                                        invalid={vname}
                                      />
                                      <InputGroupText
                                        style={{
                                          color: "#000",
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{ cursor: "pointer" }}
                                          icon={faUser}
                                        />
                                      </InputGroupText>
                                    </InputGroup>
                                    <FormFeedback
                                      className={vname ? "d-block" : ""}
                                    >
                                      Nom de l'administrateur du restaurant requis
                                    </FormFeedback>
                                  </Col>
                                  <Col>
                                    <InputGroup size="lg" className="mt-1">
                                      <Input
                                        type={"text"}
                                        name="sname"
                                        id="sname"
                                        value={sname}
                                        onChange={handleChange}
                                        style={{
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                        bsSize="lg"
                                        placeholder="Prénoms"
                                      />
                                      <InputGroupText
                                        style={{
                                          color: "#000",
                                          backgroundColor: "#eee",
                                          borderColor: "#eee",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{ cursor: "pointer" }}
                                          icon={faUserAlt}
                                        />
                                      </InputGroupText>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="position-relative">
                                <Label for="phone">
                                  Téléphone Administrateur restaurant *
                                </Label>
                                <Row
                                  noGutters
                                  className="mt-1"
                                  style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <Col sm={11}>
                                    <PhoneInput
                                      value={phone}
                                      onChange={(phone, data) => {
                                        setPhone("+" + phone);
                                        // let v = p.slice(data.dialCode.length)
                                        // console.log(phone)
                                        // setPhoneError(!isValidPhoneNumber(phone))
                                      }}
                                      inputProps={{
                                        name: "phone",
                                        // id: "tel",
                                        className: "form-control tel",
                                        required: true,
                                        autoFocus: true,
                                      }}
                                      preferredCountries={["cm"]}
                                      enableSearch="true"
                                      className="p-0"
                                      country={"cm"}
                                    />
                                  </Col>
                                  <Col
                                    sm={1}
                                    id="phoneIcon"
                                    className="text-center align-self-center d-none d-md-block"
                                  >
                                    <FontAwesomeIcon
                                      style={{ color: "#000" }}
                                      icon={faPhoneAlt}
                                    />
                                  </Col>
                                </Row>
                                <FormFeedback className={pError ? "d-block" : ""}>
                                  Numéro de Téléphone invalide
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="position-relative">
                                <Label for="phone2">
                                  Téléphone WhatsApp restaurant *
                                </Label>
                                <Row
                                  noGutters
                                  className="mt-1"
                                  style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <Col sm={11}>
                                    <PhoneInput
                                      value={phone2}
                                      onChange={(phone2, data) => {
                                        setPhone2("+" + phone2);
                                        // let v = p.slice(data.dialCode.length)
                                        // console.log(phone)
                                        // setPhoneError(!isValidPhoneNumber(phone))
                                      }}
                                      inputProps={{
                                        name: "phone2",
                                        id: "tel2",
                                        className: "form-control tel",
                                        required: false,
                                        autoFocus: true,
                                      }}
                                      preferredCountries={["cm"]}
                                      enableSearch="true"
                                      className="p-0"
                                      country={"cm"}
                                    />
                                  </Col>
                                  <Col
                                    sm={1}
                                    id="phoneIcon"
                                    className="text-center align-self-center d-none d-md-block"
                                  >
                                    <FontAwesomeIcon
                                      style={{ color: "#000" }}
                                      icon={faPhoneAlt}
                                    />
                                  </Col>
                                </Row>
                                <FormFeedback
                                  className={p2Error ? "d-block" : ""}
                                >
                                  Numéro de Téléphone invalide
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="position-relative">
                                <Label for="email">
                                  Email Administrateur restaurant
                                </Label>
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    autoComplete="false"
                                    type={"email"}
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    value={email}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Email de l'administrateur"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faEnvelope}
                                    />
                                  </InputGroupText>
                                </InputGroup>
  
                                <FormFeedback
                                  className={emailError ? "d-block" : ""}
                                >
                                  Email invalide
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col className="mb-4" md={12}>
                              <FormGroup className="position-relative">
                                <Label for="name">Choisissez la catégorie</Label>
                                <Input
                                  type={"select"}
                                  name="category"
                                  id="category"
                                  onChange={handleChange}
                                  value={category}
                                  style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                  bsSize="lg"
                                  placeholder="Choisissez la catégorie"
                                >
                                  {categoryList.map((list) => (
                                    <option value={list.id}>{list.name}</option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col className="mb-4" md={12}>
                              <FormGroup className="position-relative">
                                <Label for="name">Lieu Dit</Label>
                                <InputGroup size="lg" className="mt-1">
                                  <Input
                                    type={"text"}
                                    name="lieu-dit"
                                    id="lieu-dit"
                                    value={lieuDit}
                                    onChange={handleChange}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Précise l'emplacement du restaurant"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faLocationDot}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="position-relative">
                                <Label for="name">Adresse de localisation</Label>
                                {address !== "" ? (
                                  <InputGroup size="lg" className="mt-1">
                                    <Input
                                      type={"text"}
                                      name="address"
                                      id="address"
                                      value={address}
                                      onChange={handleChange}
                                      disabled={true}
                                      style={{
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                      bsSize="lg"
                                      placeholder="Adresse de localisation"
                                    />
                                    <InputGroupText
                                      style={{
                                        color: "#000",
                                        backgroundColor: "#eee",
                                        borderColor: "#eee",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        style={{ cursor: "pointer" }}
                                        icon={faLocationDot}
                                      />
                                    </InputGroupText>
                                  </InputGroup>
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={12} className="mb-4">
                              <FormGroup>
                                <Button
                                  type="button"
                                  onClick={() => (
                                    getPosition(), handleOpenModal()
                                  )}
                                  size="md"
                                  className="fw-bold btn btn-login"
                                  block
                                >
                                  <FontAwesomeIcon icon={faMapPin} /> &nbsp;
                                  Ouvrir la carte
                                </Button>
                              </FormGroup>
                            </Col>
                            <Modal
                              isOpen={isOpen}
                              onClose={handleCloseModal}
                              size={"5xl"}
                            >
                              <ModalHeader>
                                <b>Carte de géolocalisation</b>
                              </ModalHeader>
                              <ModalBody>
                                <ModalBodyComponent
                                  initialCoordinates={{
                                    latitude: lat ?? 4.0511,
                                    longitude: long ?? 9.7678,
                                  }}
                                  initialAddress={address}
                                  onClose={handleCloseModal}
                                  onSaveCoordinates={handleSaveCoordinates}
                                />
                              </ModalBody>
                            </Modal>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="web">Site Web</Label>
                                <InputGroup size="md" className="mt-1 mb-3">
                                  <Input
                                    type={"text"}
                                    name="website"
                                    id="website"
                                    onChange={handleChange}
                                    value={web}
                                    style={{
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                    bsSize="lg"
                                    placeholder="Site Web"
                                  />
                                  <InputGroupText
                                    style={{
                                      color: "#000",
                                      backgroundColor: "#eee",
                                      borderColor: "#eee",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      icon={faGlobeAfrica}
                                    />
                                  </InputGroupText>
                                </InputGroup>
                                <FormText>
                                  Exemple: <i>https://www.example.com</i>
                                </FormText>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="social">
                                  Autres liens (Facebook, Instagram, WhatsApp
                                  etc.)
                                </Label>
                                <CreatableSelect
                                  components={components}
                                  inputValue={social}
                                  onChange={(newValue) => setValue(newValue)}
                                  onInputChange={(newValue) =>
                                    setSocial(newValue)
                                  }
                                  onKeyDown={handleKeyDown}
                                  value={value}
                                  className="mb-3"
                                  menuIsOpen={false}
                                  placeholder="Entrez les liens"
                                  style={{
                                    backgroundColor: "#eee",
                                    borderColor: "#eee",
                                  }}
                                  isMulti
                                  isClearable
                                />
                                <FormText>
                                  Entrez le lien et appuyez <b>ENTREE</b> pour
                                  valider
                                </FormText>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup row>
                                <Label sm={12} for="profile">
                                  <b>Photo de profil du restaurant *</b>
                                </Label>
                                <Col sm={12}>
                                  {profile.length > 0 ? (
                                    <Col sm={12} className="thumb-container pb-5">
                                      {profile.map((file) => (
                                        <div className="thumb" key={file.name}>
                                          <button
                                            className="rbtn"
                                            onClick={removeFiles(file)}
                                          >
                                            <FontAwesomeIcon
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                              icon={faXmarkCircle}
                                            />
                                          </button>
                                          <div className="thumb-inner">
                                            <img
                                              style={{
                                                width: "200px",
                                                height: "125px",
                                              }}
                                              src={file.preview}
                                              alt={file.name}
                                              onLoad={() => {
                                                URL.revokeObjectURL(file.preview);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </Col>
                                  ) : (
                                    <>
                                      <Col
                                        sm={12}
                                        {...getRootProps({
                                          className:
                                            "wazi-dropzone2 justify-content-center",
                                        })}
                                      >
                                        <input
                                          {...getInputProps()}
                                          type="file"
                                          name="profile"
                                          id="profile"
                                        />
                                        <FormText color="muted">
                                          Déposer une photo ici
                                        </FormText>
                                      </Col>
                                    </>
                                  )}
                                </Col>
  
                                <FormFeedback
                                  className={profileError ? "d-block" : ""}
                                >
                                  Select a profile photo for your restaurant
                                </FormFeedback>
                              </FormGroup>
                            </Col>
                            <Col md={8}>
                              <FormGroup row>
                                <Label sm={12} for="username">
                                  <b>Photos du restaurant</b>
                                  (Maximum 3 Images)
                                </Label>
                                <Col sm={12}>
                                  <Dropzone
                                    className="mt-2"
                                    onDrop={(acceptedFiles) => {
                                      setPicture(
                                        acceptedFiles.map((file) =>
                                          Object.assign(file, {
                                            preview: URL.createObjectURL(file),
                                          })
                                        )
                                      );
                                    }}
                                    name="pictures"
                                    multiple={true}
                                    maxFiles={3}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps({
                                          className:
                                            "wazi-eats-dropzone justify-content-center",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <span
                                          className=""
                                          style={{ fontSize: ".8rem" }}
                                        >
                                          Veuillez cliquer ici pour choisir vos
                                          images
                                        </span>
                                      </div>
                                    )}
                                  </Dropzone>
                                </Col>
                                <Col
                                  sm={11}
                                  className="thumbs-container pb-5 px-4"
                                  style={{ overflow: "auto" }}
                                >
                                  {picture.map((file) => (
                                    <div className="thumbs" key={file.name}>
                                      <button
                                        className="rbtn2"
                                        onClick={removePicture(file)}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          icon={faXmarkCircle}
                                        />
                                      </button>
                                      <div className="thumbs-inner">
                                        <img
                                          style={{
                                            width: "115px",
                                            height: "115px",
                                          }}
                                          src={file.preview}
                                          alt={file.name}
                                          onLoad={() => {
                                            URL.revokeObjectURL(file.preview);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </Col>
                              </FormGroup>
                            </Col>
  
                            <Col md={12}>
                              <div>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      defaultChecked={terms}
                                      onChange={() => {
                                        setTerms(!terms);
                                      }}
                                    />
                                    <TermsModal />
                                  </Label>
                                </FormGroup>
                              </div>
                            </Col>
  
                            <FormText>
                              <i>All compulsory fields carry a *</i>.
                            </FormText>
                            <Col sm={2} className="cbtn">
                              <Button
                                type="button"
                                onClick={handleSubmit}
                                size="md"
                                className="fw-bold btn btn-login mt-2"
                                block
                              >
                                {editStatus === 0 ? "Créer" : "Enregistrer"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Form>
                    )}
                  </ModalBody>
                </LoadingOverlay>
              </Modal>
            </Col>
          </Row>
  
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{
              overflow: "hidden",
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active={loading} type="ball-pulse-rise" />}
          >
            <Table variant="dark" striped bordered hover>
              <thead>
                <tr>
                  <th>Nom</th>
                  {!isMobile ? (<th>Shops</th>) : ("")}
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {resaurantsList.map((item, key) => {
                  return (
                    <tr>
                      <td>{item.name}</td>
                      {!isMobile ? <td>{item.user_first_name + " " + item.user_last_name}  <span class="tags">{item.source}</span> </td> : ("")}
                      <td>{item.address}</td>
                      <td>
                        <Row style={{ flexDirection: "row" }}>
                          <Col>
                            <Button
                              className=" btn-icon-only pt-0 wazi-btn-add "
                              disabled="true"
                              style={{
                                backgroundColor: "green",
                                border: "1px solid green",
                              }}
                              onClick={(e) => editToggle(item)}
                            >
                              <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              className=" btn-icon-only pt-0 wazi-btn-add "
                              onClick={(e) => edit(item)}
                            >
                              <FontAwesomeIcon icon={faEye} size="xs" />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              className=" btn-icon-only pt-0 wazi-btn-add "
                              disabled="true"
                              style={{
                                backgroundColor: "red",
                                border: "1px solid red",
                              }}
                              onClick={(e) => handleDelete(item)}
                            >
                              <FontAwesomeIcon icon={faTrash} size="xs" />
                            </Button>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </LoadingOverlay>
  
          <Row>
            <Col md="12">
              <Modal
                className="wazi-modal"
                isOpen={modal2}
                toggle={toggleTwo}
                size="lg"
                backdrop="static"
              >
                <ModalHeader
                  style={{ fontSize: "1.5rem" }}
                  toggle={toggleTwo}
                  close={closeBtnTwo}
                >
                  <FontAwesomeIcon icon={faUtensils} />
                  &nbsp;&nbsp;
                  {id === 0 ? "Ajouter" : "Shops"}
                </ModalHeader>
                <ModalBody>
                  {loader ? (
                    <div className="text-center py-5">
                      <div className="text-center py-5">
                        <div className="text-center py-5">
                          <Spinner
                            thickness="4px"
                            emptyColor="gray.200"
                            color="green.500"
                            size="xl"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Nom
                        </Label>
                        <Col sm={9}>
                          <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Entrer le nom du type"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="description" sm={3}>
                          Shops
                        </Label>
                        <Col sm={9}>
                          <Input
                            value={restaurateur}
                            onChange={(e) => setRestaurateur(e.target.value)}
                            // disabled={dDesc}
                            type="text"
                            name="restaurateur"
                            id="restaurateur"
                            placeholder="Entrer la description du type"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Email
                        </Label>
                        <Col sm={9}>
                          <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Entrer le nom du type"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Address
                        </Label>
                        <Col sm={9}>
                          <Input
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            name="address"
                            id="address"
                            placeholder="Entrer le nom du type"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Phone
                        </Label>
                        <Col sm={9}>
                          <Input
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Entrer le nom du type"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Profile picture
                        </Label>
                        <Col sm={9}>
                          <Box
                            maxW="sm"
                            borderWidth="1px"
                            borderRadius="lg"
                            overflow="hidden"
                          >
                            <Image
                              src={vuePofilePicture}
                              boxSize="150px"
                              alt="Dan Abramov"
                            />
                          </Box>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="name" sm={3}>
                          Restaurant picture
                        </Label>
                        <Col sm={9} style={{ display: "flex" }}>
                          {vueRestaurantPicture.map((item, key) => {
                            return (
                              <Box
                                maxW="sm"
                                borderWidth="1px"
                                borderRadius="lg"
                                overflow="hidden"
                                class=""
                              >
                                <Image
                                  src={item.image}
                                  boxSize="150px"
                                  alt="Dan Abramov"
                                />
                              </Box>
                            );
                          })}
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      </Base>
    );
  }
  